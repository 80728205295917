function responsiveMenuHandler(menu, context) {
	const breakpoint = menu.getAttribute("bm-breakpoint") ? menu.getAttribute("bm-breakpoint") : context.breakpoint;

	const checkMenuBreakpoint = () => {
		console.log(breakpoint);
		if (window.innerWidth < breakpoint) {
			menu.setAttribute("data-better-menu", "vertical");
		} else {
			menu.setAttribute("data-better-menu", "horizontal");
		}
	};

	// Run once to make sure the menu is set
	checkMenuBreakpoint();
	// Keep polling to make sure the menu is changed when needed
	window.addEventListener("resize", checkMenuBreakpoint);
}

export default responsiveMenuHandler;
