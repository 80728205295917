/**
 * Main project app script!
 *
 * All script functionality should be in this file.
 *
 * NOTE: jQuery by default is NOT supported! Please make sure to follow ES6 paradigms and create vanilla JS
 * wherever possible. jQuery is included for the sake of plugins that we rely on such as Gravity Forms.
 * Refer to the document at https://wiki.posturedev.com for further information.
 **/

//Modules
// import { Glightbox, AOS, Swiper } from "Parent/js/modules/modules";

// All generic global site javascript should live in this file.
import Globals from './globals';
import BetterMenu from "./lib/better-menu/better-menu";
import BetterModal from "./lib/better-menu/better-modal";
import SuperActiveAnchor from "./lib/super-active-anchor";
import SuperElementWatcher from "./lib/super-element-watcher";
import $ from "jQuery";
//import { gsap } from "gsap";
//import { ScrollTrigger } from "gsap/ScrollTrigger";
//import lax from "lax.js";

//gsap.registerPlugin(ScrollTrigger);
// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	// Remove and uncomment the enqueue script in functions.php if not needed
	window.$ = $;
	document.querySelector("body").setAttribute("data-s-loaded", "");

	const globals = new Globals();
	const betterMenu = new BetterMenu();
	new BetterModal();
	new SuperActiveAnchor();
	new SuperElementWatcher();

	const mobileDropdowns = document.querySelectorAll("#mobile-nav [data-bm-dropdown-anchor]");
	mobileDropdowns.forEach(dropdown => {
		dropdown.dispatchEvent(betterMenu.events['bm-dropdown-open']);

	})

/*
	// Attach lax presets to window
	window.lax = { presets: lax.presets };
/!*
	// Start Lax
	lax.init();

	// Capture scroll events and pass to Lax
	lax.addDriver("scrollY", () => {
		return window.scrollY;
	});*!/*/


});
