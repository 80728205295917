import responsiveMenuHandler from "./handlers/responsive-handler";
import { dropdownHandler, closeDropdownEvent, openDropdownEvent } from "./handlers/dropdown-handler";

class BetterMenu {
	constructor(props) {
		this.menus = document.querySelectorAll("nav[data-better-menu]");

		this.breakpoint = 1024;
		this.dropdownTimeout = 750;
		this.dropdownTimers = {};
		this.dropdownArrow =
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 7.7"><path data-name="Icon ionic-ios-arrow-down" d="M6.7 5.4L11.8.3a1 1 0 011.4 0 1 1 0 010 1.3L7.4 7.4a1 1 0 01-1.3 0L.3 1.8A1 1 0 011.6.3z" fill="currentColor"/></svg>';
		this.menuEvents = {};

		this.isMobileDevice = false;

		const accessibilityDefaults = {
			menu_name: "Main Navigation",
		};
		this.accessibilityLabels = {};
		// All Better Menu
		this.events = {
			init: new CustomEvent("bm-init", {
				detail: {},
				bubbles: true,
				cancelable: false,
			}),
			"bm-dropdown-close": new CustomEvent("bm-dropdown-close", {
				bubbles: true,
				cancelable: true,
			}),
			"bm-dropdown-open": new CustomEvent("bm-dropdown-open", {
				bubbles: true,
				cancelable: true,
			}),
		};

		// Check to see if any menus are in the dom
		if (this.menus.length) {
			this.init();
		} else {
			console.log("no better menus found");
		}
	}

	/**
	 * initializes menus and passes to respective menu handler
	 *
	 **/
	init() {
		// Check if device is touch oriented
		window.addEventListener(
			"touchstart",
			function oneTouch() {
				this.isMobileDevice = true;

				this.menus.forEach((menu) => {
					menu.setAttribute("data-bm-is-mobile", "");
				});
			}.bind(this),
			{ once: true }
		);

		// If user uses tab default to mobile mode for accessibility
		const tabEventHandler = (event) => {
			const key = event.keyCode || event.key;
			if (key === (9 || "Tab")) {
				console.log("This is an accessibility user");
				this.isMobileDevice = true;

				this.menus.forEach((menu) => {
					menu.setAttribute("data-bm-is-mobile", "");
				});
				cancelTabEvent();
			}
		};

		const cancelTabEvent = () => {
			window.removeEventListener("keydown", tabEventHandler);
		};

		window.addEventListener("keydown", tabEventHandler);

		this.menus.forEach((menu) => {
			if (!menu.hasAttribute("aria-label")) {
				menu.setAttribute("aria-label", "Main Navigation");
			}
			const menuType = menu.getAttribute("data-better-menu");
			switch (true) {
				// Error out when a bad menu is found
				case menu.id.length === 0:
					throw {
						message: "Better Menu elements require ID to initialize",
						el: menu,
					};
				case menuType === "vertical":
					this.setupMenu(menu);
					break;
				case menuType === "horizontal":
					this.setupMenu(menu);
					break;
				case menuType === "responsive":
					responsiveMenuHandler(menu, this);
					this.setupMenu(menu);
					break;
				default:
					throw {
						message: "This Better Menu does not have a valid configuration",
						el: menu,
					};
			}
			menu.dispatchEvent(this.events.init);
		});
	}

	setupMenu(menu) {
		// Add role=navigation for accessibility
		menu.setAttribute("role", "navigation");

		// check for any active links BIG TODO this isn't very easy to do
		/*const activeAnchors = menu.querySelectorAll("a");
		const path = window.location.pathname;
		const hash = window.location.hash;
		console.log(path);
		activeAnchors.forEach((anchor) => {
			if (anchor.href.includes(path)) {
				anchor.setAttribute("data-bm-active-anchor", "");
			} else if (hash) {
				if (anchor.href.includes(hash)) {
					anchor.setAttribute("data-bm-active-anchor", "");
				}
			}
		});*/

		// The menu type
		const menuType = menu.getAttribute("data-better-menu");
		// Add LI submenu signifier
		// aria-haspopup="true" aria-expanded="true/false" goes on a el
		const dropdowns = menu.querySelectorAll("li > ul");

		// Pass
		//linkHandler(menu, this);
		dropdownHandler(menu, this);

		// Add aria-current="page" for active menu items
	}

	SetupMobile(menu) {
		// Button aria-expanded="true/false" aria-controls="(modalID)"
		// add role="navigation" to menu button
		// Aria label "Open mobile menu"
		// Add aria label aria-hidden to any child elements in button
		// Aria close button label "close mobile menu"
		// focus on both when interacted
	}

	/**
	 *
	 * @param menu - the menu that this event exists in
	 * @param eventName - The standardized event name
	 * @param el - The event element for the event to be called on
	 * @param event - The actual event function. To note: this will likely show undefined in the console
	 */
	registerMenuEvent(menu, eventName, el, event) {
		const menuID = menu.getAttribute("id");

		const addEventListener = () => {
			el.addEventListener(eventName, event);
		};
		const removeEventListener = () => {
			el.removeEventListener(eventName, event);
		};

		// Initialize the listener
		addEventListener();

		if (!this.menuEvents[menuID]) {
			this.menuEvents[menuID] = [];
		}

		this.menuEvents[menuID].push({
			eventName: eventName,
			el: el,
			addEventListener: addEventListener,
			removeEventListener: removeEventListener,
		});

		//this.pauseMenuEvent(menu, el, eventName);
		//this.resumeMenuEvent(menu, el, eventName);
	}

	pauseMenuEvent(menu, el, eventName) {
		const eventObj = this.menuEvents[menu.id].filter((eventObj) => {
			if (eventObj.el === el && eventObj.eventName === eventName) {
				return true;
			}
		});
		eventObj.forEach((obj) => {
			obj.removeEventListener();
		});
	}

	resumeMenuEvent(menu, el, eventName) {
		const eventObj = this.menuEvents[menu.id].filter((eventObj) => {
			if (eventObj.el === el && eventObj.eventName === eventName) {
				return true;
			}
		});
		eventObj.forEach((obj) => {
			obj.addEventListener();
		});
	}
}

export default BetterMenu;
