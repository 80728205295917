import { offCanvasOpen, offCanvasClose } from "./handlers/offcanvas-handler";

class BetterModal {
	constructor() {
		this.modals = document.querySelectorAll("[data-better-modal]");
		this.buttons = document.querySelectorAll("[data-bm-button]");
		this.body = document.querySelector("body");
		this.events = {
			"init": new CustomEvent("bm-init", {
				detail: {},
				bubbles: true,
				cancelable: false,
			}),
			"bm-open": new CustomEvent("bm-open", {
				bubbles: true,
				cancelable: true,
			}),
			"bm-close": new CustomEvent("bm-close", {
				bubbles: true,
				cancelable: false,
			}),
		};

		this.modalEvents = {};

		if (this.modals.length) {
			this.init();
		} else {
			console.log("No Better Modals Found");
		}
	}

	init() {
		// Modal Setup
		this.modals.forEach((modal) => {
			const modalCard = modal.querySelector("[data-bm-card]");

			// Safety checks to ensure proper function
			if (modal.id.length === 0) {
				throw {
					message: "Better Modal requires an ID to initialize",
					el: modal,
				};
			}

			if (modal.hasAttribute("data-bm-is-offcanvas")) {
				if (modal.getAttribute("data-bm-direction") === "center") {
					throw {
						message: "Better Modal is not compatible with off canvas and direction also being center",
						el: modal,
					};
				}
				this.body.setAttribute("data-bm-offcanvas", "");
			}
			// End safety checks

			// Register custom events for this modal
			this.registerModalEvent(modal, "bm-open", this.openModalEvent.bind(this));
			this.registerModalEvent(modal, "bm-close", this.closeModalEvent.bind(this));

			// Background overlay for this modal
			this.addModalOverlay(modal);

			// Add aria labels
			modal.setAttribute("aria-modal", "true");
			modal.setAttribute("role", "dialog");
			modalCard.setAttribute("tabindex", "-1");
		});

		this.buttons.forEach((button) => {
			const buttonID = button.getAttribute("data-bm-button");
			let modalEl = null;
			let validButton = false;

			// Button Safety checks
			// Check for a valid id on button
			this.modals.forEach((modal) => {
				if (modal.id === buttonID) {
					validButton = true;
					modalEl = modal;
				}
			});

			// Throw message if valid button not found
			if (!validButton) {
				throw {
					message:
						"Better Modal has found a button that does not have a valid ID: Check the ID for this button",
					el: button,
				};
			}
			// End buttons safety checks

			button.setAttribute("aria-label", "Navigation Button");
			button.setAttribute("aria-controls", buttonID);

			button.addEventListener("click", () => {
				if (modalEl.hasAttribute("data-bm-is-open")) {
					button.removeAttribute("bm-active");
					modalEl.dispatchEvent(this.events["bm-close"]);
				} else {
					modalEl.dispatchEvent(this.events["bm-open"]);
					button.setAttribute("bm-active", "");
				}
			});
		});
	}

	openModalEvent(event) {
		const modal = event.target;
		const modalCard = modal.querySelector("[data-bm-card]");
		const modalOverlay = modal.querySelector("[data-bm-overlay]");
		const button = document.querySelector(`[data-bm-button="${modal.id}"]`);
		const modalButton = modalCard.querySelector("[data-bm-button]");

		modal.setAttribute("data-bm-is-open", "");
		modalCard.setAttribute("data-bm-active", "");
		modalOverlay.setAttribute("data-bm-active", "");

		if (modal.hasAttribute("data-bm-is-offcanvas")) {
			offCanvasOpen(modalCard, modal.getAttribute("data-bm-position"));
			this.body.setAttribute("data-bm-offcanvas", modal.getAttribute("data-bm-position"));
		}

		this.body.style.overflowY = "hidden";
		this.body.style.position = "fixed";

		document.addEventListener("scroll", this.disableScrollEvent);
	}

	closeModalEvent(event) {
		const modal = event.target;
		const modalCard = modal.querySelector("[data-bm-card]");
		const modalOverlay = modal.querySelector("[data-bm-overlay]");
		const button = document.querySelector(`[data-bm-button]`);
		modal.removeAttribute("data-bm-is-open");
		modalCard.removeAttribute("data-bm-active");
		modalOverlay.removeAttribute("data-bm-active");

		if (modal.hasAttribute("data-bm-is-offcanvas")) {
			offCanvasClose(modalCard, modal.getAttribute("data-bm-position"));
		}

		// For accessibility return focus to the button that was clicked
		button.focus({
			preventScroll: true,
		});

		this.body.style.overflowY = "auto";
		this.body.style.position = "relative";

		document.removeEventListener("scroll", this.disableScrollEvent);
	}

	disableScrollEvent(event) {
		event.preventDefault();
	}
	// Add aria label navigation button

	// add aria controls for id#

	// data-bm-is-open

	// data-bm-animation

	registerModalEvent(modal, eventName, event) {
		const modalID = modal.id;

		const addEventListener = () => {
			modal.addEventListener(eventName, event);
		};

		const removeEventListener = () => {
			modal.removeEventListener(eventName, event);
		};

		// Initialize the listener
		addEventListener();

		if (!this.modalEvents[modalID]) {
			this.modalEvents[modalID] = [];
		}

		this.modalEvents[modalID].push({
			eventName: eventName,
			addEventListener: addEventListener,
			removeEventListener: removeEventListener,
			listenerActive: true,
		});
	}

	pauseModalEvent(modal, eventName) {
		const eventObj = this.modalEvents[modal.id].filter((eventObj) => {
			if (eventObj.eventName === eventName) {
				return true;
			}
		});

		eventObj.removeEventListener();
	}

	resumeModalEvent(modal, eventName) {
		const eventObj = this.modalEvents[modal.id].filter((eventObj) => {
			if (eventObj.eventName === eventName) {
				return true;
			}
		});

		eventObj.removeEventListener();
	}

	addModalOverlay(modal) {
		const overlay = document.createElement("div");
		overlay.setAttribute("data-bm-overlay", modal.id);
		modal.appendChild(overlay);

		overlay.addEventListener("click", (event) => {
			const modal = event.target.closest("[data-better-modal]");
			modal.dispatchEvent(this.events["bm-close"]);
		});
	}
}

export default BetterModal;
